<template>
  <div>
    <div>
      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12 w-100">
        <b-col
          cols="11"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
    </div>
    <!-- Error page-->
    <div class="misc-wrapper">
      <header class="header-introduce">
        <div class="btn-logout cursor-pointer">
          <b-card-text @click="onLogout">
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            {{ $t('common.textLogOut') }}
          </b-card-text>
        </div>
      </header>

      <div class="misc-inner p-2 p-sm-3">
        <!-- image -->
        <div class="bg-header-img">
          <b-img
            fluid
            :src="img"
            class="img"
            alt="img"
          />
        </div>
        <div class="w-100 text-center bg-content-block">
          <h2 class="mb-1 title">
            {{ $t('home.titleUserBlock') }}
          </h2>
          <p class="mb-2 des">
            {{ $t('home.contentUserBlock', { platformName: this.platformName }) }}
          </p>
          <user-hint />
        </div>
      </div>
      <live-chat />
    </div>
  <!-- / Error page-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BImg, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import envMixin from '@/mixins/envMixin'
import UserHint from '@/components/UserHint.vue'
import LiveChat from '@/components/LiveChat.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BrandLogo,
    BImg,
    BCardText,
    UserHint,
    LiveChat,
    BRow,
    BCol,
  },
  mixins: [envMixin],

  data() {
    return {
      img: require('@/assets/images/pages/auth/auto-block.png'),
    }
  },

  watch: {
    user: {
      handler(val) {
        if (val.status === true && val?.data?.status !== 'blocked') {
          this.$router.push('/')
        } else if (this.isEcomdyPlatform && !this.isExclusivePartner) {
          this.initFreshChat(val)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(['user']),
  },

  created() {
    this.getAccountInfo()
    this.getGeneralConfig()
  },

  methods: {
    ...mapActions(['logout', 'updateAccount', 'getAccountInfo', 'getGeneralConfig']),
    onLogout() {
      this.logout()
    },

    goToSupport() {
      if (window.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },

    initFreshChat(val) {
      const plugin = document.createElement('script')
      plugin.setAttribute(
        'src',
        'https://wchat.freshchat.com/js/widget.js',
      )
      plugin.onload = () => {
        const email = get(val, ['data', 'email'], '')
        const localUserId = get(val, ['data', 'localUserId'], null)
        const restoreId = get(val, ['data', 'freshChatRestoreId'], null)
        const fullName = get(val, ['data', 'fullName'], '')
        const adsAccount = get(val, ['data', 'adsAccounts'], [])
        const phoneData = get(val, ['data', 'contactInfo', 'phone'], 'N/A')
        const totalSpent = get(val, ['data', 'totalSpending'], 'N/A')
        const userCountryCode = get(val, ['data', 'signupTracking', 'countryName'])
        const userIp = get(val, ['data', 'signupTracking', 'ip'])
        const userAgent = get(val, ['data', 'signupTracking', 'userAgent'])
        const amId = get(val, ['data', 'amId'], 'N/A')
        const ecomdyBalance = get(val, ['data', 'balance'], 'N/A')
        const profileBaseUrl = 'https://tiktok-admin.ecomdymedia.com'
        if (email && window.fcWidget) {
          window.fcWidget.init({
            token: 'b7c5a45f-b29b-4853-ac30-950b2842854a',
            host: 'https://wchat.freshchat.com',
            locale: 'en',
            externalId: localUserId,
            restoreId,
            email,
            firstName: `${localUserId}_${userCountryCode}_`,
            lastName: fullName,
            phone: this.getPhoneData('phone'),
          })

          window.fcWidget.user.setProperties({
            phone: this.getPhoneData('phone'),
            ecomdyBalance,
            plan: this.getPlanName,
            totalSpent: `${totalSpent}$`,
            totalAdsAccount: Array.isArray(adsAccount) ? adsAccount.length : 0,
            phoneData,
            profileUrl: `${profileBaseUrl}/user/${localUserId}`,
            ip: userIp,
            userAgent,
            amId,
          })
          this.saveUserInfoToFreshChat()
        } else if (window.fcWidget) {
          window.fcWidget.init({
            token: 'b7c5a45f-b29b-4853-ac30-950b2842854a',
            host: 'https://wchat.freshchat.com',
            locale: 'en',
          })
        }
      }
      plugin.async = true
      return document.head.appendChild(plugin)
    },

    saveUserInfoToFreshChat() {
      window.fcWidget.user.get(resp => {
        const status = resp && resp.status
        if (status !== 200) {
          window.fcWidget.on('user:created', createdResp => {
            const createdStatus = createdResp && createdResp.status
            const createdData = createdResp && createdResp.data
            if (createdStatus === 200) {
              if (createdData.restoreId) {
                // Update restoreId into database
                const params = {
                  ...this.user.data,
                  freshChatRestoreId: createdData.restoreId,
                }
                this.updateAccount(params)
              }
            }
          })
        }
      })
    },

    getPhoneData(type) {
      const contactInfo = get(this.user, ['data', 'contactInfo'], {})
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = phoneElm
          switch (type) {
            case 'phone': return phone
            case 'countryCode': return countryCode
            case 'countryAbbr': return countryAbbr
            default: return ''
          }
        }
      }
      return ''
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
.img {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
}

.des {
  line-height: 24px;
}

.btn-logout {
  position: absolute;
  top: 2.5rem;
  right: 3rem;
}

.bg-header-img {
  background-color: rgba(225, 28, 78, 0.1);
  border-radius: 40px 40px 0px 0px;
  padding: 44px;
}

.bg-content-block {
  background-color: #FFFFFF;
  border-radius: 0px 0px 40px 40px;
  padding: 34px;
}

.logo-white-label {
  color: #000000;
  font-weight: 700;
}
</style>
